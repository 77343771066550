import React from 'react'
import { Link } from 'gatsby'
import ArticleMetadata from './article-metadata'

export default ({ article }) => (
  <div className="first:border-t-0 border-t border-lightestGray py-4">
    <div className="font-serif font-semibold leading-6 mb-1 text-2xl md:text-4xl">
      <Link to={`/writing/${article.slug}`}>{article.title}</Link>
    </div>
    <div
      className="font-sans font-extralight leading-5 mb-1 text-lg text-midGray md:text-2xl"
      dangerouslySetInnerHTML={{
        __html: article.description.childMarkdownRemark.html,
      }}
    />
    <ArticleMetadata article={article} textColor="text-darkBlue" />
  </div>
)
