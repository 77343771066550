import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import HelmetWrapper from '../components/helmet-wrapper'
import ArticlePreview from '../components/article-preview'

class WritingIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')

    return (
      <Layout location={this.props.location}>
        <div>
          <HelmetWrapper pageTitle="Writing" />
          <div className="p-4">
            <div className="md:max-w-5xl md:mx-auto md:mt-6">
              {posts.map(({ node }) => {
                return (
                  <ArticlePreview article={node} key={node.slug}/>
                )
              })}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default WritingIndex

export const pageQuery = graphql`
  query WritingIndexQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
          author {
            name
          }
          body {
            childMarkdownRemark {
              timeToRead
            }
          }
        }
      }
    }
  }
`
